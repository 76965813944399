import { ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextError } from "./TextError";

const SingleVideoUpload = ({ field, form, meta }) => {
    const fileRef = useRef(null);
    const videoRef = useRef(null);

    const { t } = useTranslation();

    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [preview, setPreview] = useState("");
    const [duration, setDuration] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (!!field.value && typeof field.value === "string") {
            form.setFieldValue(field.name, "");
            setPreview(process.env.REACT_APP_IMAGE_BASE_URL + field.value);
        }
        // eslint-disable-next-line
    }, [field.value]);

    useEffect(() => {
        if (!uploadedVideo) return;

        const objectUrl = URL.createObjectURL(uploadedVideo);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line
    }, [uploadedVideo]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedVideo(file);
        form.setFieldValue(field.name, file);
        setDuration(null); // Reset duration when a new file is uploaded
    };

    const handleUpload = () => {
        fileRef.current.click();
    };

    const handleCancel = (e) => {
        e.preventDefault();
        setUploadedVideo(null);
        setPreview("");
        setDuration(null);
        form.setFieldValue(field.name, "");
    };

    const handlePlayPause = () => {
        if (!videoRef.current) return;
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleLoadedMetadata = () => {
        if (videoRef.current) {
            setDuration(videoRef.current.duration.toFixed(2)); // Set video duration
        }
    };

    return (
        <div>
            <div className="relative w-full max-w-md shadow rounded overflow-hidden">
                {preview && (
                    <div className="flex flex-col items-center bg-gray-100 p-2">
                        <video
                            ref={videoRef}
                            src={preview}
                            className="w-full rounded"
                            onLoadedMetadata={handleLoadedMetadata}
                        ></video>
                        <div className="mt-2 flex items-center">
                            <button
                                type="button"
                                onClick={handlePlayPause}
                                className="text-white bg-blue-500 hover:bg-blue-700 rounded p-2 mr-2"
                            >
                                {isPlaying ? "Pause" : "Play"}
                            </button>
                            <button
                                onClick={handleCancel}
                                className="text-white bg-red-500 hover:bg-red-700 rounded p-2"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                )}
                {duration && (
                    <div className="mt-2 text-sm text-gray-600">
                        Duration: {duration} seconds
                    </div>
                )}
            </div>
            <div className="flex w-full bg-grey-lighter mt-3 relative">
                <label
                    onClick={handleUpload}
                    className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg uppercase border border-blue-300 cursor-pointer hover:bg-blue-300 hover:text-white"
                >
                    <svg
                        className="w-8 h-8"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="mt-2 text-base leading-normal">
                        {uploadedVideo ? t("Replace Video") : t("Upload Video")}
                    </span>
                </label>
                <input
                    name={field.name}
                    ref={fileRef}
                    type="file"
                    accept="video/*"
                    onChange={handleFileChange}
                    onBlur={form.handleBlur}
                    className={`hidden`}
                />
            </div>
            <ErrorMessage name={field.name} component={TextError} />
        </div>
    );
};

export default SingleVideoUpload;
