import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import APIServices from "../../../httpServices/httpServices";
import listeningSetValidationSchema from "../../../validation/ListeningSetValidation";
import SaveButton from "../../common/button/SaveButton";
import FormikImageField from "../../common/form/FormikImageField";
import FormikTextField from "../../common/form/FormikTextField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import { emitErrorToast, emitSuccessToast } from "../../common/toast/ToastContainer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FormikAudioField from "../../common/form/FormikAudioField";
import FormikVideoField from "../../common/form/FormikVideoField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import ListeningQuestionList from "../listeningQuestion/ListeningQuestionList";

const ListeningSetPage = () => {
    const { id, partId, sectionId } = useParams(); // Read part and section from URL params
    const part = partId.split("-")[1]
    const section = sectionId.split("-")[1]
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const title = searchParams.get("title") || "Listening Practice";

    const [activePart, setActivePart] = useState(parseInt(part) || 1); // Tracks the active part
    const [activeSection, setActiveSection] = useState(parseInt(section) || 1); // Tracks the active section
    const [listeningSetId, setListeningSetId] = useState("")

    // Define sections for each part
    const sectionsConfig = {
        1: 8,
        2: 5,
        3: 6,
        4: 1,
        5: 1,
        6: 1,
    };

    // Handle URL navigation
    const handleNavigation = (newPart, newSection) => {
        setActivePart(newPart);
        setActiveSection(newSection);
        const encodedTitle = encodeURIComponent(title); // Encode title for URL safety
        navigate(`/listening-set/${id}/part-${newPart}/section-${newSection}?title=${encodedTitle}`);
    };

    // Set active part and section from URL on load
    useEffect(() => {
        setActivePart(parseInt(part) || 1);
        setActiveSection(parseInt(section) || 1);
    }, [part, section]);

    const initialValues = {
        listeningGroup: id,
        questionType: activePart <= 3 ? "Single" : "Group",
        instruction: "",
        instructionImage: null,
        audio: null,
        video: null,
        duration: "",
        preparationFlag: false,
        part: activePart,
        section: activeSection,
    };

    const [form, setForm] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(true);

    const questionType = ["Single", "Group"];
    const questionTypeOptions = questionType.map((d) => ({ value: d, label: d }));

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const { success, data } = await new APIServices("listening-set/fetch/by-group-part-section").post({
                    listeningGroup: id,
                    part: activePart,
                    section: activeSection,
                });
                if (success && data) {
                    setListeningSetId(data._id)
                    setForm((prev) => ({
                        ...prev,
                        ...data,
                    }));
                } else {
                    setListeningSetId(null)
                    setForm(initialValues); // Reset to initial values if no data
                }
            } catch (error) {
                console.error("Error fetching Listening Set data", error);
                setListeningSetId(null); // Error means no valid listeningSetId
                setForm(initialValues); // Reset to initial values
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, activePart, activeSection]);

    // Handle Form Submission
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formData = new FormData();

            Object.keys(values).forEach((key) => {
                const value = values[key];
                if (value instanceof File) {
                    formData.append(key, value); // Append files directly
                } else {
                    formData.append(key, value);
                }
            });

            const { success, data, message } = await new APIServices("listening-set").post(
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            if (success) {
                emitSuccessToast("Listening Set saved successfully!");
                navigate(`/listening-question/${id}/${data._id}/part-${activePart}/section-${activeSection}`);
                resetForm();
            } else {
                emitErrorToast(message || "Error occurred while saving.");
            }
        } catch (error) {
            emitErrorToast("Failed to save Listening Set.");
        } finally {
            setSubmitting(false);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>; // Display a loading state while fetching data
    }

    return (
        <>
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <h2 className="font-bold text-xl mb-4">{title}</h2>

                {/* Tabs for Parts */}
                <div className="flex border-b mb-4">
                    {[1, 2, 3, 4, 5, 6].map((part) => (
                        <button
                            key={part}
                            className={`px-4 py-2 border-t border-l border-r rounded-t ${activePart === part ? "bg-white" : "bg-gray-200"
                                }`}
                            onClick={() => handleNavigation(part, 1)} // Navigate to the first section of the selected part
                        >
                            Part {part}
                        </button>
                    ))}
                </div>

                {/* Tabs for Sections */}
                <div className="flex border-b mb-4">
                    {[...Array(sectionsConfig[activePart]).keys()].map((_, index) => {
                        const newSection = index + 1; // Sections start from 1
                        return (
                            <button
                                key={newSection}
                                className={`px-4 py-2 border-t border-l border-r rounded-t ${activeSection === newSection ? "bg-white" : "bg-gray-200"
                                    }`}
                                onClick={() => handleNavigation(activePart, newSection)} // Navigate to the selected section
                            >
                                Section {newSection}
                            </button>
                        );
                    })}
                </div>

                {/* Form Section */}
                <Formik
                    initialValues={form}
                    validationSchema={listeningSetValidationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form className="bg-white p-6 rounded shadow-md">
                            <div className="mb-4">
                                <FormikSelectSingleField
                                    label="Select Question Type"
                                    name="questionType"
                                    options={questionTypeOptions}
                                    handleSelectSingle={(selectedOption) =>
                                        formik.setFieldValue("questionType", selectedOption.value)
                                    }
                                />
                            </div>

                            <FormikTextField
                                label="Instruction"
                                name="instruction"
                                as="textarea"
                                placeholder="Please write some instruction"
                                formik={formik}
                            />

                            <div className="mb-4">
                                <FormikImageField label="Instruction Image" name="instructionImage" />
                            </div>

                            <div className="mb-4">
                                <FormikAudioField label="Audio File" name="audio" />
                            </div>

                            {activePart === 5 && (
                                <div className="mb-4">
                                    <FormikVideoField label="Video File" name="video" />
                                </div>
                            )}

                            <FormikTextField
                                label="Duration (in seconds)"
                                name="duration"
                                formik={formik}
                                placeholder="e.g., 120"
                            />

                            <FormikSwitchField label="Preparation Flag" name="preparationFlag" />

                            <div className="flex justify-end">
                                <SaveButton isSubmitting={formik.isSubmitting} label="Save" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <ListeningQuestionList listeningSetId={listeningSetId} listeningGroupId={id} part={activePart} section={activeSection} />
        </>
    );
};

export default ListeningSetPage;


