import * as yup from "yup";

const listeningSetValidationSchema = yup.object().shape({
    instruction: yup.string(),
    instructionImage: yup.mixed(),
    audio: yup.mixed(),
    duration: yup.string(),
});

export default listeningSetValidationSchema;
