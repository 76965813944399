import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import * as Yup from 'yup';
import APIServices from "../../../httpServices/httpServices";
import DashCard from "../../common/DashCard";
import FormHeading from "../../common/FormHeading";
import QuestionOptionForm from "../../common/QuestionOptionForm";
import SaveButton from "../../common/button/SaveButton";
import FormikAudioField from "../../common/form/FormikAudioField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextAreaField from "../../common/form/FormikTextAreaField";
import FormikTextField from "../../common/form/FormikTextField";
import {
    emitErrorToast,
    emitSuccessToast,
} from "../../common/toast/ToastContainer";
import ListeningQuestionOptionForm from "../../common/ListeningQuestionOptionForm";

const ListeningQuestionForm = ({ editData }) => {

    const { groupId, id, partId, sectionId } = useParams(); // Read part and section from URL params
    const part = partId.split("-")[1]
    const section = sectionId.split("-")[1]
    const navigate = useNavigate();


    const initialValues = {
        listeningSet: id,
        status: true,
        title: "",
        slug: "",
        questionAudio: "",
        optionType: "Text",
        part: part,
        section: section,
        orderBy: 1,
        type: "Dropdown",
        hint: "",
        explanation: "",
        options: [],
        timeLimit: 0,
    };


    const [form, setForm] = useState({ ...initialValues });


    let optionType = [
        { label: "Text", value: "Text" },
        { label: "Image", value: "Image" }
    ]

    useEffect(() => {
        if (editData) {
            const processedOptions =
                Array.isArray(editData.options) &&
                editData.options.map((option) => ({
                    name: editData.optionType === "Text" ? option.option : null, // Text options
                    image: editData.optionType === "Image" ? option.option : null, // Image options
                    correctAnswer: option.correctAnswer, // Boolean value
                }));

            setForm((prev) => ({
                ...prev,
                ...editData,
                options: processedOptions || [], // Default to an empty array if no options
            }));
        }
    }, [editData]);



    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions;

        let formData = new FormData();

        // Append top-level fields
        Object.keys(values).forEach((key) => {
            if (key === "status") {
                formData.append(key, values[key] ? "Active" : "Inactive");
            } else if (key !== "options") {
                formData.append(key, values[key]);
            }
        });

        // Append options based on optionType
        if (values.options && Array.isArray(values.options)) {
            values.options.forEach((option, index) => {
                if (values.optionType === "Image" && option.image) {
                    // Append image file
                    formData.append(`options[${index}][option]`, option.image);
                } else if (values.optionType === "Text" && option.name) {
                    // Append text value
                    formData.append(`options[${index}][option]`, option.name);
                }
                // Append correctAnswer as boolean
                formData.append(`options[${index}][correctAnswer]`, option.correctAnswer ? "true" : "false");
            });
        }

        // API call
        const { success, message } = !!!editData
            ? await new APIServices(`listening-question`).post(formData)
            : await new APIServices(`listening-question/${editData._id}`).put(formData);

        if (success) {
            emitSuccessToast(message);
            setSubmitting(false);
            resetForm();
            navigate(`/listening-set/${groupId}/part-${part}/section-${section}`);
        } else {
            emitErrorToast(message);
        }
    };



    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value);
    };


    const validate = Yup.object().shape({
        status: Yup.boolean(),
        title: Yup.string(),
        // OrderBy: !editData ? Yup.number()
        //   .required('Required').notOneOf(OrderList, "Order number already exists. please make it unique")
        //   : Yup.number().required('Required'),
        type: Yup.string().required("Required !"),
    });

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-6xl px-4 mx-auto ">
                <Formik
                    initialValues={form}
                    validationSchema={validate}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form>
                            <div className="grid gap-4">
                                <div>
                                    <FormHeading title={`Add Listening Questions for  for Part ${part} section ${section}`} />
                                    <DashCard>
                                        <div className="mb-4">
                                            <FormikAudioField label="Question Audio" name="questionAudio" />
                                        </div>
                                        <FormikTextField
                                            label="title"
                                            name="title"
                                            placeholder="Enter a title"
                                            formik={formik}
                                        />

                                        {/* <FormikSelectSingleField
                                            label="type "
                                            name="type"
                                            options={typeOptions}
                                            handleSelectSingle={handleSelectSingle}
                                        /> */}

                                        <FormikSelectSingleField
                                            label="optionType "
                                            name="optionType"
                                            options={optionType}
                                            handleSelectSingle={handleSelectSingle}
                                        />

                                        {formik.values.type === "Radio" ||
                                            formik.values.type === "Dropdown" ||
                                            formik.values.type === "Checkbox" ? (
                                            <ListeningQuestionOptionForm formik={formik} />
                                        ) : null}

                                        <FormikTextField
                                            label="Order By"
                                            name="orderBy"
                                            formik={formik}
                                            type="number"
                                        />
                                        <FormikTextField
                                            label="Time Limit"
                                            name="timeLimit"
                                            formik={formik}
                                            type="number"
                                        />
                                        <FormikTextField
                                            label="hint"
                                            name="hint"
                                            as="textarea"
                                            formik={formik}
                                        />
                                        <FormikTextAreaField
                                            label="explanation"
                                            name="explanation"
                                            as="textarea"
                                            formik={formik}
                                        />
                                        <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                                            <FormikSwitchField label="status" name="status" />
                                        </div>
                                    </DashCard>
                                </div>
                            </div>
                            <SaveButton isSubmitting={formik.isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    );
};

export default ListeningQuestionForm;
