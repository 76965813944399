import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DOMPurify from "dompurify";
import DeleteModal from "../../common/modals/DeleteModal";
import CustomImage from "../../common/CustomImage";

const ViewCategory = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [itemDetails, setItemDetails] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (id) {
            getItemDetail();
        }
        // eslint-disable-next-line
    }, []);

    // Fetch User Data
    const getItemDetail = async () => {
        const { data, success } = await new APIServices(`category/${id}`).get();
        if (success) {
            setItemDetails(data);
        }
    };

    const handleShowDeleteModal = () => {
        setShowModal(true);
    };

    const handleDelete = async () => {
        const { message, success } = await new APIServices(`category/${id}`).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/category");
        }
    };

    return (
        <section className="pb-16 pt-8 bg-white dark:bg-gray-800 ">
            <div className="max-w-4xl px-4 mx-auto">
                {itemDetails?.status === "Inactive" && (
                    <button
                        onClick={handleShowDeleteModal}
                        className="flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5"
                    >
                        Delete
                    </button>
                )}
                <h2 className="text-2xl font-semibold mb-1 font-poppins dark:text-gray-300">
                    {itemDetails?.title}
                </h2>
                <span className="mb-7 block text-sm text-gray-600">
                    {" "}
                    Published On : {itemDetails?.publishedOn}
                </span>
                <CustomImage
                    alt=""
                    source={itemDetails?.image ? itemDetails?.image : ""}
                    className="object-cover w-full h-96"
                />
                <div
                    className="pt-5 pb-5  "
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(itemDetails.detail),
                    }}
                />
            </div>
            {showModal && (
                <DeleteModal handleDelete={handleDelete} close={setShowModal} />
            )}
        </section>
    );
};

export default ViewCategory;
