import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import ListeningQuestionForm from "../../container/listeningQuestion/ListeningQuestionForm";

const EditListeningQuestion = () => {
    const { questionId } = useParams();
    const [editData, setEditData] = useState({});

    const getDetail = async () => {
        const { data, success } = await new APIServices(`listening-question/${questionId}`).get();
        if (success) {
            setEditData({
                ...data,
                status: data?.status === "Active" ? true : false,
            });
        }
    };
    useEffect(() => {
        !!questionId && getDetail();
        // eslint-disable-next-line
    }, [questionId]);

    return <ListeningQuestionForm editData={editData} />;
};

export default EditListeningQuestion;
