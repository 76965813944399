import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import ListeningGroupForm from "../../container/listeningGroup/ListeningGroupForm";

const EditListeningGroup = () => {
    const { id } = useParams();
    const [editData, setEditData] = useState({});

    const getDetail = async () => {
        const { data, success } = await new APIServices(`listening-group/${id}`).get();
        if (success) {
            setEditData({
                ...data,
                status: data?.status === "Active" ? true : false,
            });
        }
    };
    useEffect(() => {
        !!id && getDetail();
        // eslint-disable-next-line
    }, [id]);

    return <ListeningGroupForm editData={editData} />;
};

export default EditListeningGroup;
