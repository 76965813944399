import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import SingleVideoUpload from "../SingleVideoUpload";

const FormikVideoField = ({ label, name }) => {
    const { t } = useTranslation();
    return (
        <div className="mb-6">
            <label className="block mb-2 text-sm font-medium" htmlFor={label}>
                {t(label)}
            </label>
            <Field name={name}>
                {({ field, form, meta }) => {
                    return <SingleVideoUpload field={field} form={form} meta={meta} />;
                }}
            </Field>
        </div>
    );
};

export default FormikVideoField;
