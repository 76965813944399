import { ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextError } from "./TextError";

const SingleAudioUpload = ({ field, form, meta }) => {
    const fileRef = useRef(null);
    const audioRef = useRef(null);

    const { t } = useTranslation();

    const [uploadedAudio, setUploadedAudio] = useState(null);
    const [preview, setPreview] = useState("");
    const [duration, setDuration] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (!!field.value && typeof field.value === "string") {
            form.setFieldValue(field.name, "");
            setPreview(process.env.REACT_APP_IMAGE_BASE_URL + field.value);
        }
        // eslint-disable-next-line
    }, [field.value]);

    useEffect(() => {
        if (!uploadedAudio) return;

        const objectUrl = URL.createObjectURL(uploadedAudio);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line
    }, [uploadedAudio]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedAudio(file);
        form.setFieldValue(field.name, file);
        setDuration(null); // Reset duration when a new file is uploaded
    };

    const handleUpload = () => {
        fileRef.current.click();
    };

    const handleCancel = (e) => {
        e.preventDefault();
        setUploadedAudio(null);
        setPreview("");
        setDuration(null);
        form.setFieldValue(field.name, "");
    };

    const handlePlayPause = () => {
        if (!audioRef.current) return;
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration.toFixed(2)); // Set audio duration
        }
    };

    return (
        <div>
            <div className="relative w-64 shadow rounded overflow-hidden">
                {preview && (
                    <div className="flex items-center justify-between bg-gray-100 p-2">
                        <audio
                            ref={audioRef}
                            src={preview}
                            controls={false}
                            onLoadedMetadata={handleLoadedMetadata}
                        ></audio>
                        <button
                            type="button"
                            onClick={handlePlayPause}
                            className="text-white bg-blue-500 hover:bg-blue-700 rounded p-2"
                        >
                            {isPlaying ? "Pause" : "Play"}
                        </button>
                        <button
                            onClick={handleCancel}
                            className="text-white bg-red-500 hover:bg-red-700 rounded p-2 ml-2"
                        >
                            Remove
                        </button>
                    </div>
                )}
                {duration && (
                    <div className="mt-2 text-sm text-gray-600">
                        Duration: {duration} seconds
                    </div>
                )}
            </div>
            <div className="flex w-full bg-grey-lighter mt-3 relative">
                <label
                    onClick={handleUpload}
                    className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg uppercase border border-blue-300 cursor-pointer hover:bg-blue-300 hover:text-white"
                >
                    <svg
                        className="w-8 h-8"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="mt-2 text-base leading-normal">
                        {uploadedAudio ? t("Replace Audio") : t("Upload Audio")}
                    </span>
                </label>
                <input
                    name={field.name}
                    ref={fileRef}
                    type="file"
                    accept="audio/*"
                    onChange={handleFileChange}
                    onBlur={form.handleBlur}
                    className={`hidden`}
                />
            </div>
            <ErrorMessage name={field.name} component={TextError} />
        </div>
    );
};

export default SingleAudioUpload;
