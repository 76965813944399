import { FieldArray } from "formik";
import React from "react";
import FormikSwitchField from "./form/FormikSwitchField";
import FormikTextField from "./form/FormikTextField";
import FormikImageField from "./form/FormikImageField";

const ListeningQuestionOptionForm = ({ formik }) => {

    return (
        <FieldArray name="options">
            {({ push, remove }) => (
                <div>
                    <button
                        className="flex items-center justify-center px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                        type="button"
                        onClick={() => push({ name: "", image: null, correctAnswer: false })}
                    >
                        Add Option
                    </button>
                    {formik.values.options.map((_, index) => (
                        <div key={index}>
                            <div className="flex space-x-2">
                                {formik.values.optionType === "Image" ?
                                    <FormikImageField
                                        name={`options[${index}].image`}
                                        label={`Option ${index + 1} image`}
                                    /> :
                                    <FormikTextField
                                        name={`options[${index}].name`}
                                        label={`Option ${index + 1} name`}
                                    />
                                }

                                <FormikSwitchField label={`Option ${index + 1} Correct Answer`} name={`options[${index}].correctAnswer`} />

                                <div className="mt-7">
                                    <button
                                        className="px-6 py-2.5 font-medium text-white bg-red-500 rounded p-3  right-4"
                                        type="button"
                                        onClick={() => remove(index)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-x-lg"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </FieldArray>
    );
};

export default ListeningQuestionOptionForm;
