import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";


const FrontUsersList = () => {
  const navigate = useNavigate();
  const tableHeading = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "isVerified",
    "countryCode",
    "readingCompletedTests",
    "readingAverageScore",
    "status",
    "Action",
  ];
  const unverifiedTableHeading = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "isVerified",
    "countryCode",
    "Action",
  ];
  const [customerList, setCustomerList] = useState([]);
  const [unverifiedUserList, setUnverifiedUserList] = useState([]);

  // eslint-disable-next-line
  const selectedCustomerId = null;
  const [showModal, setShowModal] = useState(false);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");

  const limit = LIMIT

  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [currentUnverifiedPage, setCurrentUnverifiedPage] = useState(1);
  const [numberOfTotalUnverifiedData, setNumberOfTotalUnverifiedData] = useState(0);
  const [totalPagesNumberUnverified, setTotalPageNumberUnverified] = useState(0);
  const [isLoadingUnverified, setIsLoadingUnverified] = useState(true);

  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfUsers();

      }, 1500);
    } else {
      getListOfUsers();

    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentPage]);

  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfUnverifiedUsers();

      }, 1500);
    } else {
      getListOfUnverifiedUsers();

    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentUnverifiedPage]);

  const getListOfUsers = async () => {
    const { data, success, total, totalPages } = await new APIServices(
      "user/list"
    ).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
      isVerified: true,
    });

    if (success) {
      setIsLoading(false);
      setCustomerList(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };
  const getListOfUnverifiedUsers = async () => {
    const { data, success, total, totalPages } = await new APIServices(
      "user/list"
    ).post({
      page: currentUnverifiedPage,
      limit: limit,
      search: searchTxt,
      isVerified: false,
    });

    if (success) {
      setIsLoadingUnverified(false);
      setUnverifiedUserList(data);
      setTotalPageNumberUnverified(totalPages || 0);
      setNumberOfTotalUnverifiedData(total || 0);
    }
  };

  const handleDeleteUser = async () => {
    const { message, success } = await new APIServices(
      `user/${selectedCustomerId}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfUsers();
    }
  };

  const handleEditUser = (id) => {
    navigate(`/users/edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/users/view/${id}`);
  };

  const navigateToAddNewUser = () => {
    navigate("/users/add");
  };

  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };

  return (
    <section className="dark:bg-gray-800 ">
      <div className="max-w-10xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry
                title="List of Users"
                // navigate={navigateToAddNewUser}
                handleTableSearch={handleTableSearch}
              />
              <CustomTable
                tableData={customerList}
                tableHeadingData={tableHeading}
                handleDelete={false}
                // handleEdit={handleEditUser}
                handleView={handleView}
                actionField="_id"
              />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
                numberOfTotalData={numberOfTotalData}
                limit={limit}
              />
            </>
          )}
        </div>

        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoadingUnverified ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry
                title="List of Unverified Users"
                // navigate={navigateToAddNewUser}
                handleTableSearch={handleTableSearch}
              />
              <CustomTable
                tableData={unverifiedUserList}
                tableHeadingData={unverifiedTableHeading}
                handleDelete={false}
                // handleEdit={handleEditUser}
                handleView={handleView}
                actionField="_id"
              />
              <TablePagination
                currentPage={currentUnverifiedPage}
                setCurrentPage={setCurrentUnverifiedPage}
                totalPagesNumber={totalPagesNumberUnverified}
                numberOfTotalData={numberOfTotalUnverifiedData}
                limit={limit}
              />
            </>
          )}
        </div>
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDeleteUser} close={setShowModal} />
      )}
    </section>
  );
};

export default FrontUsersList;
